import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from './routes';

class PageRoutes extends React.Component {
  
  render() {
    return (
      <Routes>
        <Route {...ROUTES.STUDENT_DASHBOARD} />
        <Route {...ROUTES.SUBSCRIPTION} />
        <Route {...ROUTES.AUTH} />
        <Route {...ROUTES.VIEW_CLASS} />
        <Route {...ROUTES.UPCOMING_CLASS} />
        <Route {...ROUTES.INVITE_CODE} />
        <Route {...ROUTES.WAILIST} />
        <Route {...ROUTES.SIGNUP_SUCCESS} />
        <Route {...ROUTES.DELTE_ACCOUNT} />
        <Route {...ROUTES.CONTACT} />
        <Route {...ROUTES.PROFILE} />
        <Route {...ROUTES.SUBSCRIPTION_PLANS} />
        <Route {...ROUTES.REFER_A_FRIEND} />
        <Route {...ROUTES.PAST_CLASSES} />
        <Route {...ROUTES.UPCOMING_CLASS_DETAILS} />
        <Route {...ROUTES.JOINMEETING} />
        <Route {...ROUTES.WAITING_FOR_TEACHER} />
      </Routes>
    );
  }
}

export default PageRoutes;
