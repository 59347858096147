import { load } from '../utilities';
import PageRoutes from './PageRoutes';

export const ROUTES = {
  PAGE_ROUTES: {
    path: '/*',
    element: <PageRoutes />
  },
  STUDENT_DASHBOARD: {
    path: '/',
    exact: true,
    element: load('dashboard/StudentDashboard')()
  },
  AUTH: {
    path: '/auth',
    exact: true,
    element: load('user/SignupLogin')()
  },
  VIEW_CLASS: {
    path: '/class/:classUrl',
    exact: true,
    element: load('class/ViewClass')()
  },
  UPCOMING_CLASS: {
    path: '/upcoming-classes',
    exact: true,
    element: load('class/UpcomingClass')()
  },
  INVITE_CODE: {
    path: '/invite/:inviteCode?',
    exact: true,
    element: load('user/InviteCode')()
  },
  WAILIST: {
    path: '/waitlist',
    exact: true,
    element: load('user/WaitListUser')()
  },
  SUBSCRIPTION: {
    path: '/subscription-management',
    exact: true,
    element: load('user/SubscriptionManagement')()
  },
  SIGNUP_SUCCESS: {
    path: '/signup-success',
    exact: true,
    element: load('user/SignupSuccess')()
  },
  DELTE_ACCOUNT: {
    path: '/delete-account',
    exact: true,
    element: load('user/DeleteAccount')()
  },
  CONTACT: {
    path: '/contactus',
    exact: true,
    element: load('contact/ContactUs')()
  },
  PROFILE: {
    path: '/profile',
    exact: true,
    element: load('user/Profile')()
  },
  SUBSCRIPTION_PLANS: {
    path: '/subscription-plans',
    exact: true,
    element: load('plan/SubscriptionPlan')()
  },
  REFER_A_FRIEND: {
    path: '/refer-a-friend',
    exact: true,
    element: load('refer/ReferAFriend')()
  },
  PAST_CLASSES: {
    path: '/past-classes',
    exact: true,
    element: load('user/PastClasses')()
  },
  JOINMEETING: { 
    path: '/join-meeting/:id/:token',
    exact: true,
    element: load('jitsi/JoinMeeting')()
  },
  WAITING_FOR_TEACHER: { 
    path: '/join-meeting/:id',
    exact: true,
    element: load('jitsi/JoinMeeting')()
  },
  UPCOMING_CLASS_DETAILS: {
    path: '/upcoming-class-details/:id',
    exact: true,
    element: load('class/BookingDetails')()
  },
};